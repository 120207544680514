import { Radar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: {
    chartData: Object,
  },
  methods: {
    calculatePercentage(value, min, max) {
      if (min === max) {
        return 100;
      }
      return ((value - min) * 100) / (max - min);
    },
    calculateInversePercentage(value, min, max) {
      if (min === max) {
        return 100;
      }
      return 100 - ((value - min) * 100) / (max - min);
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: ["Einfachheit", "Codequalität", "Schnelligkeit"],
        datasets: [
          {
            label: "Nutzer",
            backgroundColor: "rgba(80, 151, 151, 0.2)",
            borderColor: "#509797",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [
              this.calculateInversePercentage(
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.value,
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.min,
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.max
              ),
              this.calculateInversePercentage(
                this.chartData.measures.OVERALL_ISSUES_COUNT.value,
                this.chartData.measures.OVERALL_ISSUES_COUNT.min,
                this.chartData.measures.OVERALL_ISSUES_COUNT.max
              ),
              this.calculateInversePercentage(
                this.chartData.measures.HISTORY_WORKING_TIME.value,
                this.chartData.measures.HISTORY_WORKING_TIME.min,
                this.chartData.measures.HISTORY_WORKING_TIME.max
              ),
            ],
          },
          {
            label: "Durchschnitt",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: [
              this.calculatePercentage(
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.avg,
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.min,
                this.chartData.measures.CYCLOMATIC_COMPLEXITY.max
              ),
              this.calculatePercentage(
                this.chartData.measures.OVERALL_ISSUES_COUNT.avg,
                this.chartData.measures.OVERALL_ISSUES_COUNT.min,
                this.chartData.measures.OVERALL_ISSUES_COUNT.max
              ),
              this.calculateInversePercentage(
                this.chartData.measures.HISTORY_WORKING_TIME.avg,
                this.chartData.measures.HISTORY_WORKING_TIME.min,
                this.chartData.measures.HISTORY_WORKING_TIME.max
              ),
            ],
          },
        ],
      },
      {
        scale: {
          ticks: {
            max: 100,
            min: 0,
            stepSize: 20,
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label += "scored " + Math.round(tooltipItem.yLabel * 10) / 10 + "% of the best submission";
              return label;
            },
          },
        },
        maintainAspectRatio: true,
      }
    );
  },
};
