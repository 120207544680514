<template>
  <div>
    <div class="row">
      <p-metric-card v-for="metric in measures" :key="metric.id" :metric="metric" />
    </div>
  </div>
</template>

<script>
import PMetricCard from "@/components/report-base/PMetricCard";

export default {
  components: {
    PMetricCard,
  },
  props: {
    measures: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.chart-row {
  flex-wrap: wrap;
}

.chart-container {
  background-color: white;
  box-shadow: $elevation1;
  border-radius: 5px;
}
</style>
