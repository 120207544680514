import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: Object,
    highlight: Array,
  },
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.chartData.regions,
      datasets: [
        {
          label: "Score Verteilung",
          backgroundColor: this.highlight,
          data: this.chartData.values,
        },
      ],
    });
  },
};
