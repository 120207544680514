<template>
  <div>
    <div class="flow-test-viewer">
      <div>
        <div class="panel-heading" style="padding-top: 0px;"
          ><h1>{{ task.title }}</h1></div
        >
        <div class="panel-body">
          <p v-html="task.description"></p>
          <p style="margin: 3rem 0 0 0;"> </p>
        </div>
      </div>

      <div class="list-group scenarios">
        <div
          v-for="scenario in flowContent.scenarios"
          :id="scenario.id"
          :key="scenario.id"
          class="list-group-item scenario panel panel-default"
        >
          <h3 class="list-group-item-heading">{{ scenario.title }}</h3>
          <p class="list-group-item-text" v-html="scenario.description"></p>
          <p class="traceback alert alert-danger scenario-error" style="display: none"></p>
          <hr class="scenario-hr" />
          <div v-html="scenario.test"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint vue/no-v-html: 0 */

export default {
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    flowContent: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
};
</script>

<style type="text/css">
.flow-test-viewer {
  border-color: #ddd;
  overflow-y: auto;
  margin: 5px;
}

.flow-test-viewer .scenario {
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #fafafa;
  box-shadow: 0 3px 3px rgba(50, 50, 93, 0.1), 0 5px 10px rgba(0, 0, 0, 0.07);
}

.flow-test-viewer .list-group-item-heading {
  margin-bottom: 1rem;
}

.flow-test-viewer .list-group-item-text {
  margin-bottom: 1em;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #7ac142;
  }
}

.rounded-icon {
  display: inline-block;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
  font-size: 2.5rem;
  margin-right: 10px;
  margin-left: 10px;
}

.survey-container {
  font-size: 2rem;
}

.scenarios {
  color: black;
}

.spin-fix {
  padding: 0 0.15rem !important;
}

.panel-error > h3 > .fa {
  color: #c0392b;
}

.panel-error > h3 {
  padding-left: 1rem;
}

sentence {
  display: block !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 2rem;
  border-left: 6px solid;
  border-left-color: #27ae60;
  background-color: white;
}

.flow-test-viewer sentence:nth-child(odd) {
  background-color: #fafafa;
}

.flow-test-viewer div.successful-scenario > .flow-test-viewer sentence:not(.error),
.flow-test-viewer sentence.success {
  border-left-color: #27ae60;
}

.flow-test-viewer sentence.error {
  border-left-color: #c0392b;
}

.flow-test-viewer sentence.error > * {
  margin-top: 1em;
}

.scenarios {
  position: relative;
}

.test-error,
.traceback {
  font-family: "Source Code Pro", monospace;
  font-size: 1.2rem;
  white-space: pre;
  overflow-x: scroll;
}

.test-error {
  border-left: 6px #c0392b solid;
  padding-left: 1rem;
}

.test-error-alert {
  overflow-x: scroll;
  overflow-y: hidden;
}

parameter {
  border-radius: 3px;
  color: currentColor;
  font-family: "Source Code Pro", Menlo, Monaco, "Courier New", monospace;
  background: rgba(41, 128, 185, 0.1);
  padding: 1px 6px;
  font-size: 13px;
}
code {
  color: #2980b9;
  background: inherit;
  font-family: "Source Code Pro", Menlo, monospace;
  font-size: 13px;
}

.open-in-editor {
  color: whitesmoke !important;
  font-family: "Source Code Pro", Menlo, Monaco, "Courier New", monospace;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 6px;
  border-radius: 3px;
  font-size: 13px;
}

parameter > .open-in-editor {
  margin: 1px -5px;
}

a.open-in-editor:hover,
a.open-in-editor:active {
  color: white;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.6);
}

a.open-in-editor:before {
  content: "\f121";
  font-family: FontAwesome;
  font-weight: 200;
  font-style: normal;
  margin-right: 3px;
  text-decoration: none !important;
  text-align: center;
}

a.open-in-editor,
a.open-in-editor:visited {
  color: whitesmoke;
  font-family: "Source Code Pro", Menlo, Monaco, "Courier New", monospace;
  text-decoration: none !important;
}
</style>
