export default {
  base: "vs",
  inherit: true,
  rules: [
    {
      background: "e2e9ff",
      token: "text.html source.active4d",
    },
    {
      foreground: "000000",
      token: "text.xml",
    },
    {
      foreground: "af82d4",
      token: "comment.line",
    },
    {
      foreground: "af82d4",
      token: "comment.block",
    },
    {
      foreground: "329998",
      token: "string",
    },
    {
      foreground: "66ccff",
      fontStyle: "bold",
      token: "string.interpolated variable",
    },
    {
      foreground: "a8017e",
      token: "constant.numeric",
    },
    {
      foreground: "66ccff",
      fontStyle: "bold",
      token: "constant.other.date",
    },
    {
      foreground: "66ccff",
      fontStyle: "bold",
      token: "constant.other.time",
    },
    {
      foreground: "a535ae",
      token: "constant.language",
    },
    {
      foreground: "6392ff",
      fontStyle: "bold",
      token: "variable.other.local",
    },
    {
      foreground: "0053ff",
      fontStyle: "bold",
      token: "variable",
    },
    {
      foreground: "6988ae",
      token: "variable.other.table-field",
    },
    {
      foreground: "5463e5",
      fontStyle: "bold",
      token: "keyword",
    },
    {
      foreground: "ff5600",
      token: "storage",
    },
    {
      foreground: "21439c",
      token: "entity.name.type",
    },
    {
      foreground: "21439c",
      token: "entity.name.function",
    },
    {
      foreground: "7a7a7a",
      token: "meta.tag",
    },
    {
      foreground: "016cff",
      token: "entity.name.tag",
    },
    {
      foreground: "963dff",
      token: "entity.other.attribute-name",
    },
    {
      foreground: "45ae34",
      fontStyle: "bold",
      token: "support.function",
    },
    {
      foreground: "b7734c",
      token: "support.constant",
    },
    {
      foreground: "a535ae",
      token: "support.type",
    },
    {
      foreground: "a535ae",
      token: "support.class",
    },
    {
      foreground: "a535ae",
      token: "support.variable",
    },
    {
      foreground: "ffffff",
      background: "990000",
      token: "invalid",
    },
    {
      foreground: "ffffff",
      background: "656565",
      token: "meta.diff",
    },
    {
      foreground: "ffffff",
      background: "1b63ff",
      token: "meta.diff.range",
    },
    {
      foreground: "000000",
      background: "ff7880",
      token: "markup.deleted.diff",
    },
    {
      foreground: "000000",
      background: "98ff9a",
      token: "markup.inserted.diff",
    },
    {
      foreground: "5e5e5e",
      token: "source.diff",
    },
  ],
};
