<template>
  <input
    id="version-slider"
    :value="value"
    class="slider"
    type="range"
    :min="min"
    :max="max"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  border-radius: 50%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #cbcbcb;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #4a897d;
  transform: scale(1.6);
}

input[type="range"]::-webkit-slider-thumb {
  transition: transform 0.2s linear;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $eh-green;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.6px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #cbcbcb;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #cbcbcb;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #4a897d;
  transform: scale(1.6);
}

input[type="range"]::-moz-range-thumb {
  transition: transform 0.2s linear;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $eh-green;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #cbcbcb;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
  background: #cbcbcb;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]::-ms-thumb:hover {
  background: #4a897d;
  transform: scale(1.6);
}

input[type="range"]::-ms-thumb {
  transition: transform 0.2s linear;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $eh-green;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #cbcbcb;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #cbcbcb;
}
</style>
