<template>
  <div class="col-xl-4 col-lg-6 card-wrapper">
    <div class="metric-card">
      <div class="title-container">
        <h2 class="title">{{ metric.title }}</h2>
      </div>
      <p class="description">{{ metric.longDescription }}</p>
      <p v-if="metric.name !== 'HISTORY_WORKING_TIME'" class="value">
        {{ metric.value }}
        <span class="unit">{{ metric.unit }}</span>
      </p>
      <p v-if="metric.name === 'HISTORY_WORKING_TIME'" class="value">
        {{ prettifiedTime }}
        <span class="unit">hh:mm:ss</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metric: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    prettifiedTime() {
      return new Date(this.metric.value * 1000).toISOString().substr(11, 8);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-bottom: 2rem;
  display: flex;
}

.metric-card {
  padding: 2rem 2rem;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $elevation1;

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      height: 15%;
      color: $eh-green;
    }
  }

  .description {
    font-size: 1rem;
    color: #aaa;
  }

  .unit {
    margin-left: 10px;
    font-size: 1rem;
  }

  .value {
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
  }
}
</style>
