<template>
  <div>
    <div id="editor" ref="editor" :style="currentStyle">
      <div v-if="fatalError">
        <p class="alert alert-danger">Es ist etwas schiefgegangen bitte lade die Seite nochmal neu!</p>
      </div>
      <div class="editor-loading-overlay" :class="{ hidden: editorLoaded }">
        <p class="text-center loading">
          <span class="fa fa-cog fa-spin fa-5x loading-spinner"></span>
        </p>
      </div>
    </div>
    <div ref="hiddenEditor" style="display: none"></div>
  </div>
</template>

<script>
import * as monaco from "monaco-editor-core";
import ReconnectingWebSocket from "reconnecting-websocket";

import theme from "@/utils/editorTheme";

const normalizeUrl = require("normalize-url");

self.MonacoEnvironment = {
  getWorkerUrl: function(moduleId, label) {
    return "/js/editor.worker.bundle.js";
  },
};

// supported programming-languages
import "monaco-languages/release/esm/javascript/javascript.contribution";
import "monaco-languages/release/esm/java/java.contribution";
import "monaco-languages/release/esm/python/python.contribution";
import "monaco-languages/release/esm/php/php.contribution";
import "monaco-languages/release/esm/csharp/csharp.contribution";
import "monaco-languages/release/esm/cpp/cpp.contribution";
import "monaco-languages/release/esm/dockerfile/dockerfile.contribution";
import "monaco-languages/release/esm/html/html.contribution";
import "monaco-languages/release/esm/typescript/typescript.contribution";

export default {
  name: "EditorX",
  props: {
    workspaceUrl: { type: String, default: null },
    programmingLanguage: { type: String, default: "java" },
    code: { type: String, default: "" },
    previousCode: { type: String, default: "" },
    readOnly: { type: Boolean, default: false },
    minimap: { type: Boolean, default: true },
    filePath: { type: String, default: "" },
    theme: { type: String, default: "vs-light" },
    width: { type: [String, Number], default: "100%" },
    height: { type: [String, Number], default: "100%" },
    scrollBeyondLastLine: { type: Boolean, default: true },
  },
  data() {
    return {
      rootUri: "file:///data/task-data",
      model: null,
      editor: null,
      editorWorkerService: null,
      webSocket: null,
      fatalError: false,
      editorLoaded: false,
      currentStyle: null,
      decorations: [],
    };
  },
  watch: {
    code: function() {
      let modified = monaco.editor.createModel(this.code, this.programmingLanguage);
      let original = window.monaco.editor.createModel(this.previousCode);

      let oldEndLine = this.editor.getModel().getLineCount();
      let oldMaxColumn = this.editor.getModel().getLineMaxColumn(oldEndLine);

      this.editor.getModel().applyEdits([
        {
          forceMoveMarkers: false,
          range: new monaco.Range(0, 0, oldEndLine, oldMaxColumn),
          text: this.code,
        },
      ]);
      this.editor.getModel().forceTokenization(modified.getLineCount());

      this.editorWorkerService.computeDiff(original.uri, modified.uri, true).then(
        (result) => {
          if (!result || result.length < 1) {
            return;
          }
          let startLineNumber = result[0].modifiedStartLineNumber;
          let endLineNumber = result[0].modifiedEndLineNumber;
          if (typeof startLineNumber === "undefined" || typeof endLineNumber === "undefined") {
            return;
          }

          this.editor.revealLinesInCenterIfOutsideViewport(startLineNumber, endLineNumber, 0);

          this.decorations = this.editor.deltaDecorations(this.decorations, [
            {
              range: new monaco.Range(startLineNumber, 0, endLineNumber, modified.getLineMaxColumn(endLineNumber)),
              options: { marginClassName: "changedLine", isWholeLine: true },
            },
          ]);
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },
  mounted() {
    this.initializeEditor();

    // when resizing the window the editor should also be resized
    this.$nextTick(function() {
      window.addEventListener("resize", this.calculateStyle);
    });

    this.calculateStyle();
  },
  destroyed() {
    this.model.dispose();
    this.editor.dispose();
    this.webSocket.close();
  },
  methods: {
    initializeEditor: function() {
      let diffEditor = window.monaco.editor.createDiffEditor(this.$refs.hiddenEditor);
      this.editorWorkerService = diffEditor._editorWorkerService;

      this.model = monaco.editor.createModel(
        this.code,
        this.programmingLanguage,
        monaco.Uri.parse(this.rootUri + "/" + this.filePath)
      );
      this.editor = monaco.editor.create(this.$refs.editor, {
        model: this.model,
        glyphMargin: true,
        language: this.programmingLanguage,
        lightbulb: {
          enabled: true,
        },
        readOnly: this.readOnly,
        minimap: {
          enabled: this.minimap,
          renderCharacters: false,
        },
        automaticLayout: true,
        wordBasedSuggestions: false,
        scrollBeyondLastLine: this.scrollBeyondLastLine,
      });
      this.editor.layout();
      this.setEditorTheme(this.editor);
      this.model.onDidChangeContent((event) => {
        // eventBus.$emit("editorChangedContent", { fileContent: this.model.getValue() });
      });

      // this is a current workaround for an ready event of the monacho editor
      // (https://github.com/Microsoft/monaco-editor/issues/115)
      let didScrollChangeDisposable = this.editor.onDidScrollChange(
        function() {
          didScrollChangeDisposable.dispose();
          this.editorLoaded = true;
        }.bind(this)
      );
    },
    createWsUrl: function(path) {
      const protocol = location.protocol === "https:" ? "wss" : "ws";
      const workspaceUrlWithOutProtocol = this.workspaceUrl.replace("http://", "").replace("https://", "");

      return normalizeUrl(`${protocol}://${workspaceUrlWithOutProtocol}/${path}`);
    },
    createWebSocket: function(url) {
      const socketOptions = {
        maxReconnectionDelay: 10000,
        minReconnectionDelay: 1000,
        reconnectionDelayGrowFactor: 1.3,
        connectionTimeout: 10000,
        // TODO: check if this causes trouble because websocket connection is closed and ls support don't work
        // We have to do this for disabling multiple ls registrations because socket is reopened automatically
        maxRetries: 10,
        debug: false,
      };
      return new ReconnectingWebSocket(url, undefined, socketOptions);
    },
    setEditorTheme: function(editor) {
      monaco.editor.defineTheme("entwicklerheld", theme);
      monaco.editor.setTheme("entwicklerheld");
    },
    calculateStyle() {
      const { width, height } = this;
      const fixedWidth = width.toString().indexOf("%") !== -1 ? width : `${width}px`;

      // Get the window height browser independently
      let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const fixedHeight = `${windowHeight}px`;
      this.currentStyle = {
        width: fixedWidth,
        height: fixedHeight,
        margin: "6px auto",
        "box-sizing": "border-box",
      };
    },
  },
};
</script>

<style scoped>
#editor {
  width: 800px;
  height: 600px;
}

.loading {
  position: absolute;
  top: 45%;
  width: 100%;
}

.loading-spinner {
  color: #41a499;
  font-family: FontAwesome !important;
}

.editor-loading-overlay {
  background-color: white;
  z-index: 100;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.2s;
}

.hidden {
  display: none;
}
</style>
<style>
#editor * {
  font-family: "Source Code Pro", "Courier New", "Courier", monospace;
}

.changedLine {
  background: rgb(64, 164, 153);
}

.changedLine + .line-numbers {
  color: white !important;
}

.myLineDecoration {
  background: lightblue;
  width: 50px !important;
  margin-left: 3px;
}
</style>
